<template>
    <b-overlay :show="loading">
        <!-- 1 -->
        <b-card no-body class="mb-4">
            <h3 class="p-3">Bank List</h3>
            <!-- Header -->
            <b-card-body class="p-3">
                <div class="d-flex justify-content-between mb-2">
                    <b-form-group class="mb-2 w-25">
                        <b-input-group>
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button
                            variant="primary"
                            class="mr-2"
                            @click="submitScreenings"
                            :disabled="selected.length === 0"
                            >Submit selected to Screening</b-button
                        >
                    </div>
                </div>
                <b-table-simple show-empty responsive small>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th>
                                <b-form-checkbox v-model="selectAll" @change="selectAllHandler"></b-form-checkbox>
                            </b-th>
                            <b-th v-for="head in fields" :key="head.key">
                                {{ head.label }}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="item in screenings" :key="item.customerId">
                            <b-th>
                                <b-form-checkbox v-model="selected" :value="item.customerId"></b-form-checkbox>
                            </b-th>
                            <b-td v-for="field in fields" :key="field.key">
                                <span v-if="field.key == 'submitedToReviewDate' || field.key == 'birthDate'">
                                    {{ $formatDate(item[field.key]) }}
                                </span>
                                <span v-else>{{ item[field.key] }}</span>
                            </b-td>
                            <!-- <b-th></b-th> -->
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-card-body>
            <!-- End Section -->
        </b-card>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as dayjs from 'dayjs';

export default {
    name: 'screening-requests',

    data: () => ({
        filter: null,
        fields: [
            {
                key: 'customerId',
                label: 'Customer ID',
            },
            {
                key: 'nationalID',
                label: 'National ID',
            },
            {
                key: 'customerFullName',
                label: 'Full Name',
            },

            {
                key: 'address',
                label: 'Address',
            },

            {
                key: 'birthDate',
                label: 'Birth Date',
            },
            {
                key: 'submitedToReviewDate',
                label: 'Date submitted',
            },

            // {
            //     key: 'bankDescription',
            //     label: 'Employer(Banks)',
            // },
            // {
            //     key: 'action',
            //     label: 'Action',
            // },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],

        loading: true,
        screenings: [],
        selected: [],
        selectAll: false,
    }),
    components: {},
    methods: {
        async getData() {
            this.loading = true;
            const response = await this.$store.dispatch('customer/screening/get_pendingRequests');
            this.screenings = response ?? [];
            this.loading = false;
        },
        selectAllHandler() {
            if (this.selectAll) {
                this.selected = this.screenings.map((screening) => screening.customerId);
            } else {
                this.selected = [];
            }
        },
        async submitScreenings() {
            this.loading = true;
            await this.$store.dispatch('customer/screening/submit_toPendingVerification', this.selected);
            this.generatePDF();
            await this.getData();
            this.loading = false;
            this.selected = [];
            this.selectAll = false;
        },

        async generatePDF() {
            const fieldsPDF = [
                { key: 'id', label: 'No' },
                { key: 'customerFullName', label: 'Name' },
                { key: 'address', label: 'Address' },
                { key: 'nationalID', label: 'National ID' },
                { key: '', label: 'Balance' },
                { key: '', label: 'Monthly Payment' },
                { key: '', label: 'Comments' },
            ];

            let screeningSelected = this.screenings.filter((screening) => this.selected.includes(screening.customerId));

            screeningSelected = screeningSelected.map((screening, index) => {
                return {
                    ...screening,
                    id: index + 1,
                };
            });

            const doc = new jsPDF();

            const currentDateAsText = dayjs().format('dddd, DD MMMM YYYY');
            doc.setFontSize(10);
            doc.setFont('Helvetica');

            // Encabezado
            doc.text('Mack’s Total Finance VBA', 14, 10);
            doc.text('Adriaan Laclé Blvd 28, lok 7', 14, 15);
            doc.text('Oranjestad, Aruba', 14, 20);
            doc.text('Tel: (297) 525-3400', 14, 25);
            doc.text('Fax: (297) 588-3510', 14, 30);

            doc.setFont('Helvetica', 'bold');
            doc.text(`${currentDateAsText}`, 14, 40);
            doc.setFont('Helvetica', 'normal');
            doc.text(
                'On behalf of Total Finance, we would appreciate your assistance with the screening of the following customer(s).',
                14,
                45,
            );

            doc.setFontSize(16);
            doc.setFont('Helvetica', 'normal');

            const tableColumns = fieldsPDF.map((field) => field.label);
            const tableRows = screeningSelected.map((item) =>
                fieldsPDF.map((field) => {
                    const value = item[field.key] || '';
                    return value;
                }),
            );

            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: 60,
                theme: 'grid',
                headStyles: { fillColor: [42, 42, 42] },
                columnStyles: {
                    2: { cellWidth: 30 },
                },
            });

            // Pie de página
            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.setFontSize(10);
                doc.text(`Page ${i} of ${pageCount}`, 14, 290);
                if (i === pageCount) {
                    doc.text('Thank you in advance for the collaboration', 14, 260);
                    doc.text('Regards,', 14, 265);
                    doc.setFont('Helvetica', 'bold');
                    doc.text(`${this.user.UserName}`, 14, 270);
                    doc.setFont('Helvetica', 'normal');
                    doc.text(`e: ${this.user.Email}`, 14, 275);
                }
            }

            doc.save('screening-requests.pdf');
        },
    },
    computed: {
        ...mapGetters({
            authorizations: 'authorizations/getAuthorizations',
            user: 'auth/getUser',
        }),
    },
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
